import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone"
import ContactIcon from "../../images/contact-fab.png"

import "./_FAB.scss"

const ContactFAB = ({ language }) => {
  const isSpanish = language === "es"
  return (
    <Link
      className="fab__contact"
      to={isSpanish ? "/es/contactenos/" : "/contact-us/"}
      aria-label="navigate to contact page"
    >
      <FontAwesomeIcon icon={faPhone} color="#fff" />
      <span>{isSpanish ? "Contáctenos" : "Contact Us"}</span>
    </Link>
  )
}

export default ContactFAB
