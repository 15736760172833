import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Image, Placeholder } from "cloudinary-react"
const cldData = require("../../config.json")
import { toSentenceCase } from "../utils/utils"

// Transformations not compatible when using lazyload

const ImageMeta = props => {
  const [isLoaded, setIsLoaded] = useState(false)
  const cldImages = cldData.images.resources
  let alt = ""

  if (!props.publicId) {
    return <></>
  }
  for (let i = 0; i < cldImages.length; i++) {
    if (cldImages[i].context && props.publicId === cldImages[i].public_id) {
      alt = cldImages[i].context.custom.alt
      break
    } else {
      const splitName = props.publicId.split("/DEV/") //split cloudinary prefix
      const name = splitName[1] || splitName[0]
      alt = toSentenceCase(name.replace(/[/_-]/g, " "))
    }
  }

  const quality = props.quality || 99
  const dataSrc = `https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto/v1/${props.publicId}`

  const imageClasses = classNames(props.className, {})

  const handleLoaded = () => {
    setIsLoaded(true)

    if (props.hasLoaded) {
      props.hasLoaded(props.publicId)
    }
  }

  return (
    <Image
      onLoad={() => handleLoaded()}
      data-src={dataSrc}
      quality={quality}
      alt={alt}
      responsive
      {...props}
      className={imageClasses}
    >
      {props.loading && props.loading === "lazy" && <Placeholder type="blue" />}
      {props.children}
    </Image>
  )
}

ImageMeta.propTypes = {
  publicId: PropTypes.string.isRequired,
  children: PropTypes.element,
  noLazyload: PropTypes.bool,
  className: PropTypes.string
}

export default ImageMeta
