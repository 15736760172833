/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import ComMenu from "./Menu/ComMenu.js"
import "../css/main.scss"
import "../../node_modules/aos/dist/aos.css"
import { config } from "@fortawesome/fontawesome-svg-core"
import { CloudinaryContext } from "cloudinary-react"
import ComFooter from "../components/footer/ComFooter"
import { detectIE } from "../components/Toast/comIEDetect"
import { getScrollData } from "../../scroll-data"
import ScrollTracker from "../../scroll-tracker"
import SkipContent from "./SkipContent/SkipContent"
import ContactFAB from "./FAB/ContactFAB.js"

config.autoAddCss = false

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: true,
      browserStatus: false,
      browserName: "",
      pageLoaded: false,
      scrollPercentage: 0,
      toasted: false,
      iPhoneBottomNavVisible: false
    }

    this.refHandlers = {
      toaster: ref => (this.toaster = ref)
    }

    this.addToast = (toastMessage, toastclassName, toastAction) => {
      this.toaster.show({
        message: toastMessage,
        className: toastclassName,
        action: toastAction
      })
    }

    this.handleiPhoneScroll = this.handleiPhoneScroll.bind(this)
  }

  componentDidMount() {
    const isBrowser = typeof window !== "undefined"
    const AOS = isBrowser ? require("aos") : undefined

    this.aos = AOS
    this.aos.init({ disable: "mobile" })

    var that = this
    this.setState({
      innerHeight: window.innerHeight
    })
    var version = detectIE()
    if (version === false) {
      this.setState({ browserStatus: true }, () => {
        afterBrowserStatus(that)
      })
      window.addEventListener("scroll", () => {
        getScrollData(that)
      })
    }

    function afterBrowserStatus(that) {
      if (that.state.browserStatus) {
        that.setState({ pageLoaded: true })
      }
    }
    window.addEventListener("resize", this.handleiPhoneScroll)
  }

  componentDidUpdate() {
    this.aos.refresh()
  }

  handleiPhoneScroll() {
    let oldHeight = this.state.innerHeight
    if (window.innerHeight > oldHeight) {
      this.setState({
        innerHeight: window.innerHeight,
        iPhoneBottomNavVisible: true
      })
    } else {
      this.setState({
        innerHeight: window.innerHeight,
        iPhoneBottomNavVisible: false
      })
    }
  }

  componentDidUpdate() {
    if (this.props.toastText && !this.state.toasted) {
      this.setState({ toasted: true })
    }
    document.documentElement.classList.remove("theater-is-open")
  }

  toggleToast() {
    this.setState(prevState => ({
      toasted: !prevState.toasted
    }))
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleiPhoneScroll)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query test {
            allProcedures: allProceduresJson {
              nodes {
                title
                name
                blurb
              }
            }
            searchIndex: allSitePage(
              filter: {
                context: {
                  language: { ne: "es" }
                  searchHeading: { ne: null }
                  metaDescription: { ne: null }
                  hasThisPage: { eq: true }
                }
              }
            ) {
              nodes {
                context {
                  metaDescription
                  searchHeading
                  title
                  url
                }
              }
            }
            searchIndexEs: allSitePage(
              filter: {
                context: {
                  language: { eq: "es" }
                  searchHeading: { ne: null }
                  metaDescription: { ne: null }
                  hasThisPage: { eq: true }
                }
              }
            ) {
              nodes {
                context {
                  metaDescription
                  searchHeading
                  title
                  url
                }
              }
            }
            allStatefulPages: allSitePage(
              filter: { isCreatedByStatefulCreatePages: { eq: true } }
            ) {
              edges {
                node {
                  path
                }
              }
            }
          }
        `}
        render={data => {
          if (this.state.browserStatus) {
            return (
              <>
                <ScrollTracker scrollPercentage={this.state.scrollPercentage} />
                <SkipContent id="main-content" />
                <CloudinaryContext
                  responsiveUseBreakpoints="true"
                  cloudName="nuvolum"
                  quality="auto"
                  secure={true}
                  crop="scale"
                  width="auto"
                  fetchFormat="auto"
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%"
                    }}
                  >
                    <main>
                      <ComMenu
                        browserStatus={this.state.browserStatus}
                        pageData={data}
                        test="test"
                        language={this.props.language}
                      />
                      <ContactFAB language={this.props.language} />
                      <div
                        id="main-content"
                        className={
                          this.props.pageTitle + " " + this.props.className
                        }
                      >
                        {this.props.children}
                      </div>

                      <ComFooter
                        pageTitle={this.props.pageTitle}
                        language={this.props.language}
                      />
                    </main>
                  </div>
                </CloudinaryContext>
              </>
            )
          } else {
            return (
              <>
                <SkipContent id="main-content" />
                <CloudinaryContext
                  responsiveUseBreakpoints="true"
                  cloudName="nuvolum"
                  quality="auto"
                  secure={true}
                  crop="scale"
                  width="auto"
                  fetchFormat="auto"
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%"
                    }}
                  >
                    <main>
                      <ComMenu
                        browserStatus={this.state.browserStatus}
                        pageData={data}
                        test="test"
                        language={this.props.language}
                        boldMenu
                      />
                      <ContactFAB />
                      <div id="main-content">{this.props.children}</div>
                    </main>
                  </div>

                  <ComFooter
                    pageTitle={this.props.pageTitle}
                    language={this.props.language}
                  />
                </CloudinaryContext>
              </>
            )
          }
        }}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
